import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const Editwallet = () => {
    const params = useParams();
    const [wallet, setwallet] = useState(null);
    const { isLoading: isLoadingwallets, refetch: getwallets } = useQuery(
        "edit-wallet",
        async () => {
          return await apiClient.get(`/api/edit-wallet/${params.id}`);
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setwallet(res.data.wallet);
                setwalletForm({...walletForm, 
                    name : res.data.wallet.coin_name	,
                    address : res.data.wallet.address	,
                });
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialLogin = {
        name : ''	,
        address : '',
        wallet_type: '',
        display_area: ''
    }
    
    
    const [error, setErros] = useState('');
    const [walletForm, setwalletForm] = useState(initialLogin);

    const onChange = (e) =>
    setwalletForm({ ...walletForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postwallet } = useMutation(

        async () => {
          return await apiClient.post(`/api/update-wallet/${params.id}`, {
            coin_name : walletForm.name,	
            address : walletForm.address,	
            wallet_type : walletForm.wallet_type,
            display_area : walletForm.display_area,


          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postwallet();
      } 

      useEffect(() => {
        getwallets();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        {wallet !== null && 

        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        EDIT WALLET ADDRESS
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to edit wallet address
                    </Typography>

                    <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50, marginBottom: '10px' }}>
                      <InputLabel htmlFor="display_area">Display Area</InputLabel>
                      <Select
                          onChange={onChange}
                          defaultValue={wallet.display_area}
                          labelId="display_area"
                          name="display_area"
                          id="display_area"
                          label={`Display Area`}
                          >
                          <MenuItem selected value=''>
                            Select where to display this payment method
                            </MenuItem> 
                          <MenuItem selected value='withdrawals'>
                          withdrawals
                          </MenuItem> 
                          <MenuItem selected value='payments'>
                          deposits/payments
                          </MenuItem> 
                      </Select>                        
                    </FormControl>

                    <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50, marginBottom: '10px' }}>
                      <InputLabel htmlFor="wallet_type">Wallet Type</InputLabel>
                      <Select
                          onChange={onChange}
                          defaultValue={wallet.wallet_type}
                          labelId="wallet_type"
                          name="wallet_type"
                          id="wallet_type"
                          label={`Wallet Type`}
                          >
                          <MenuItem selected value=''>
                            Select Option
                            </MenuItem> 
                          <MenuItem selected value='bank'>
                          Bank
                          </MenuItem> 
                          <MenuItem selected value='Crypto'>
                          Crypto
                          </MenuItem> 
                      </Select>                        
                    </FormControl>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Wallet Name">wallet Name/Bank Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            defaultValue={wallet.coin_name}
                            id="name"
                            name="name"
                            type={'text'}
                            label="wallet Name/Bank Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            defaultValue={wallet.coin_name}
                            id="name"
                            name="name"
                            type={'text'}
                            label="wallet Name/Bank Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">Address/Bank Account Details</InputLabel>
                            {(error !== '' && error.address) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="address"
                            defaultValue={wallet.address}
                            type={'text'}
                            name="address"
                            label="Address/Bank Account Details"
                            helperText={error.address}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            defaultValue={wallet.address}
                            id="address"
                            type={'text'}
                            name="address"
                            label="Address/Bank Account Details"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.address}
                            </Typography>
                            
                        </FormControl>

                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Updating...' : 'Update wallet'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }
    </div>
  )
}

export default Editwallet

import React, {Fragment, useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';

import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ListItemButton from '@mui/material/ListItemButton';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import LockClockIcon from '@mui/icons-material/LockClock';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import AddchartIcon from '@mui/icons-material/Addchart';
import { useNavigate } from 'react-router-dom';

import { setCopyings } from '../../request/Analysis/hitorySlice';
import { useDispatch } from 'react-redux';
import { useMutation } from "react-query";
import apiClient from "../../request/http-common";
import { Currency } from '@depay/local-currency';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';


import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CopySingle = () => {
  
  const user = useSelector((state) => state.user.user);
  const appMode = useSelector((state) => state.mode.mode);

  const params = useParams();
  const dispatch = useDispatch();
  const [rate, setRate] = useState(1);

  const [traderId, settraderId] = useState();
  const [selectedtrader, setSelectedtrader] = useState('');
  const [source, setSource] = useState('');
  const [proportion, setProportion] = useState('');
  const [error, setErros] = useState('');
  const navigate = useNavigate();

  const callRate = async()=> {
    if(user.currency === 'USD'){
      setRate(1);
    }else{
      await Currency.fromUSD({ amount: 1 }).then(amount=>{
        setRate(amount.amount);
       });
    }  
};



  const traders = useSelector((state) => state.traders.traders);
  const getDate = (date) =>new Date(date).toLocaleString('en-GB', {
    hour12: false,
  });
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const calcPercentage = (profit, loss) => {
    let total = parseFloat(profit) + parseFloat(loss);
    let percent = profit/total;

    return percent * 100;

 }
 
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

 

  const { isLoading: isInvesting, mutate: postInvestment } = useMutation(
    async () => {
      return await apiClient.post(`/api/complete-copy`, {
        copier_id: traderId
      });
    },
    {
      onSuccess: (res) => {
          if (res.data.status === 'success') {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            dispatch(setCopyings(res.data.investments));
            setTimeout(() => {
              navigate('/copy-history');
            }, 2000);
          }            
      },
      onError: (err) => {
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }
      },
    }
  );

  
    const [open, setOpen] = React.useState(false);
    const investments = useSelector((state) => state.history.copyings);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{marginTop: '75px'}}>

      {investments.map(copier => (
        parseInt(copier.id) === parseInt(params.id) &&
        
        <Fragment key={copier.id}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{display:'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>

              <ListItem alignItems="flex-start" mb={0}>
                <ListItemAvatar>
                  <Avatar alt="trader" src={copier.trader.image} />
                </ListItemAvatar>
                <ListItemText
                  primary={copier.trader.trader_name}
                  secondary={copier.trader.level}
                />
              </ListItem>
              {copier.status === 'completed' ?
              <Button disabled fullWidth={true} size="large" color='error' variant="contained">
                stoped 
              </Button>
              :
              <Button fullWidth={true} size="large" color='error' variant="contained" 
              onClick={()=> {
                handleClickOpen();
                settraderId(copier.id);}}
              >
              stop copying
              </Button>
              }
              
                
            </Box>
            <Divider/>
            </Grid>
          </Grid>
          
              <Grid container  sx={{ marginTop: '-15px'}} px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ width: '100%' }}>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Trades" {...a11yProps(1)} />
                      </Tabs>
                    </Box>

                    <CustomTabPanel value={value} index={0}>
                      {/* summary content starts here */}

                      <Card sx={{ borderRadius: '9px', height: '100%', paddingX: '10px', bgcolor: '#36492d'}}>
                        <CardContent sx={{ paddingY: '12px', paddingX: '5px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px'}}>
                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                    Amount Invested
                                </Typography>

                                <Typography sx={{fontSize: 16, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                    {formatPrice(copier.amount)}
                                </Typography>
                            </Box>
                            <Divider/>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px'}}>
                                <Box>
                                    {parseInt(copier.trader.risk_score) < 3  &&
                                    <Chip label={`No Risk`} color="success" /> 

                                    }
                                    {(parseInt(copier.trader.risk_score) > 2 && parseInt(copier.risk_score) < 5 )&& 
                                    <Chip label={`Low Risk`} color="warning" /> 
                                    }
                                    {parseInt(copier.trader.risk_score) > 4 &&
                                    <Chip label={`Medium Risk`} color="error" /> 
                                    }
                                </Box>

                                <Box>
                                    <IconButton color="secondary" aria-label="add an alarm">
                                      <HelpIcon />
                                    </IconButton>
                                </Box>
                                
                            </Box>
                            <Divider/>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px'}}>
                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                    Proportion
                                </Typography>

                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="caption" component="div">
                                    X{copier.proportion}
                                </Typography>
                            </Box>  
                            <Divider/>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px'}}>
                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                    Gain
                                </Typography>

                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="caption" component="div">
                                    {copier.gain}%
                                </Typography>
                            </Box>  
                            <Divider/>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px'}}>
                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                    Profit
                                </Typography>

                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="caption" component="div">
                                    {formatPrice(copier.profit)}
                                </Typography>
                            </Box>  
                            <Divider/>

                            
                            {/* <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px', alignContent: 'center'}}>
                                <Box>
                                    <Typography my={0} variant="caption" color="text.secondary">
                                        Equity
                                    </Typography>
                                    <Typography sx={{ color: '#09b509', fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                    {formatPrice(copier.equity)}
                                </Typography>
                                </Box>

                                <Box>
                                    <Typography my={0} variant="caption" color="text.secondary">
                                        Commission
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                    {(copier.trader.commission)}%
                                </Typography>
                                </Box>

                                <Box>
                                    <Typography my={0} variant="caption" color="text.secondary">
                                        Floating Profit
                                    </Typography>
                                    <Typography sx={{fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                    {formatPrice(copier.floating_profit)}
                                    </Typography>
                                </Box>
                            </Box> */}

                    </CardContent>
                    </Card>
                      
                      {/* summary content stops here */}
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                      {copier.trader.trades.length > 0 ?
                      copier.trader.trades.map(trade=>(
                        <Accordion key={trade.id} sx={{borderRadius: '10px', marginBottom: '10px'}} >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box sx={{display: 'flex', justifyContent: 'space-between', width: '95%', alignItems: 'center'}}>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                              <Box mb={2} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                                <Chip label={trade.trade_type} sx={{fontSize: '10px'}} size="small" color="success" /> 
                                  <Typography ml={1} my={0} variant="caption" color="text.secondary">
                                    {trade.order_id}
                                  </Typography>
                              </Box>
                              <Typography sx={{fontWeight: 'bolder'}}>{trade.currency}</Typography>
                            </Box>

                            <Box  sx={{display: 'flex', flexDirection: 'column'}}>

                              <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                                  <LockClockIcon /> 
                                  <Typography py={1} ml={1} variant="caption">{trade.status}</Typography>
                              </Box>
                              {trade.amount > 0 ?
                                <Typography sx={{color: '#19ff01', fontWeight: 'bolder'}}>${trade.amount}</Typography> 
                               :
                                <Typography sx={{color: '#ff1414', fontWeight: 'bolder'}}>${trade.amount}</Typography>
                                }
                              
                            </Box>
                          </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Open Time</Typography>
                            <Typography py={1} variant="caption" >{getDate(trade.open_time)}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Close Time</Typography>
                            
                            <Typography py={1} variant="caption" >{getDate(trade.close_time)}</Typography>
                          </Box>
                          <Divider/>

                          {/* <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Open Price</Typography>
                            
                            <Typography py={1} variant="caption" >{trade.open_price}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Close Price</Typography>
                            
                            <Typography py={1} variant="caption" >{trade.close_price}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Order ID</Typography>
                            
                            <Typography py={1} variant="caption" >#{trade.order_id}</Typography>
                          </Box>
                          <Divider/> */}

                          {/* <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Commission You Paid</Typography>
                            
                            <Typography py={1} variant="caption" >${trade.commission}</Typography>
                          </Box> */}

                          
                              <AdvancedRealTimeChart
                                symbol= {`FX:${trade.currency}`}
                                interval= "1"
                                timezone= "Etc/UTC"
                                theme= {appMode}
                                locale= "en"
                                hide_top_toolbar= {true}
                                enable_publishing= {false}
                                save_image={false}
                                hide_side_toolbar={true}
                                container_id= {trade.id}
                                isTransparent = {true}
                                height={400}
                                width="100%"
                              >
                               </AdvancedRealTimeChart>

                               {appMode === 'dark'? 
                          <Box sx={{height: '20px', backgroundColor: 'black', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 10}}>
                          
                          </Box>
                          :
                          <Box sx={{height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 10}}>
                          
                          </Box>
                          }
                        
                        </AccordionDetails>
                      </Accordion>
                      ))                      
                      :
                      <Alert severity="info">No records yet!</Alert>
                      }
                      
                    </CustomTabPanel>
                  </Box>
              </Grid>
          </Grid>
        </Fragment>

      ))}



       
      <Dialog
        
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle sx={{textAlign: 'center'}}>{"FUND YOUR WALLET"}</DialogTitle> */}

        <DialogContent>

          <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
          Are you sure you want to stop copying this trader?
                     
          </DialogContentText>

          <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '15px'}}>
            
          <Button onClick={handleClose} fullWidth={false} size="small" color="error" variant="contained"> 
            Cancel
          </Button> 

          <Button onClick={postInvestment} fullWidth={false} size="small" color="primary" variant="contained"> 
            {isInvesting ? 'Stoping...' : 'Stop Copying'}  
          </Button>         
          </Box> 

               
          
        </DialogContent>
        
      </Dialog>
    
    </div>
  )
}

export default CopySingle

import React, {useState, useEffect, Fragment} from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import apiClient from "../../request/http-common";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRedirect } from '../Auth/historySlice';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import ListSubheader from '@mui/material/ListSubheader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CardActions } from '@mui/material';
import { Currency } from '@depay/local-currency';
import { useQuery } from 'react-query';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { setDepayTransactions } from '../../request/Analysis/hitorySlice';

import { QRCode } from 'react-qrcode-logo';
import TextField from '@mui/material/TextField';

import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import { useMutation } from 'react-query';
import { setFundings } from '../Wallet/walletSlice';
import SendIcon from '@mui/icons-material/Send';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Receive = () => {
  const [error, setErros] = useState('');

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const addresses = useSelector((state) => state.coins.addresses);
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const [coin, setCoin] = useState('');
    const [address, setAddress] = useState('');

    const dispatch = useDispatch();
    const [amount, setAmount] = useState(null);
    const [buttonText, setbuttonText] = useState(null);
    const [amountText, setAmountText] = useState(null);
    const [open, setOpen] = React.useState(false);
    const coins = useSelector((state) => state.coins.coins);
    const [conArray, setCoinArray] = useState([]);
    const coinRates = useSelector((state) => state.coins.coinRates);
    const availableCoins = ['USDT', 'USDTERC20', 'USDTTRC20', 'XMR', 'XLM', 'ETH', 'TRX', 'XRP', 'BTC', 'LTC', 'BCH', 'DOGE', 'DASH', 'BNB'];

    let acceptedCoins = [];
    const loadCoins = () => {

      if (addresses.length > 0) {
        addresses.forEach(address => {
          acceptedCoins.push(address.coin_name);
          setCoinArray(acceptedCoins);
        });
      }

    }

    const { isLoading: isSendingRequest, mutate: postRequest } = useMutation(

        async () => {
          return await apiClient.post(`/api/save-funding`, {
            amount: amount,
            coin: coin.id
          });
        },
        {
          onSuccess: (res) => {
            dispatch(setFundings(res.data.fundings))
            setCoin('');
    
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            setTimeout(() => {
              navigate('/transactions');
            }, 2000);
          },
          onError: (err) => { 
            // setCoin('');
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
            //   setErros(myerror.errors)  
              const event2 = new Event('processed');
              window.dispatchEvent(event2); 
            }else{
              
              let errMessage = err.response?.data.message || err;     
    
              localStorage.setItem('notification', JSON.stringify({message : `${errMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
              const event2 = new Event('processed');
              window.dispatchEvent(event2); 
            }
                
          },
        }
      );

    useEffect(() => {
        loadCoins();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
      
      {coin === '' ?


          <Fragment>

          {coinRates.length > 0 &&
            

      <List sx={{ width: '100%', marginTop: '20px', bgcolor: '#36492d' }}
      subheader={<ListSubheader sx={{zIndex: 0, bgcolor: '#36492d', textAlign: 'center', position: 'relative'}}>SELECT ANY PAYMENT METHOD BELOW</ListSubheader>}
      >

      {addresses.map(address => (
        
        (address.display_area === 'payments')
          
          && 
         <Fragment key={address.coin_name}>
        <ListItem alignItems="flex-start">
        <ListItemAvatar>
            <Avatar alt="Coin Name"
            src={`https://assets.coincap.io/assets/icons/${address.coin_name.toLowerCase()}@2x.png`}
            />
        </ListItemAvatar>

        <ListItemText
          primary={address.coin_name}
          secondary={
            <React.Fragment>
            </React.Fragment>
          }
        />
        <Button onClick={() => {
        setCoin(address);
        setAddress(address.address);
        // addresses.forEach(address => {
        //   if (address.coin_name === coin.coin_name) {
            
        //   }
        // });
        
        }} fullWidth={false} edge="end" size="small" color="primary" variant="contained"> 
          Deposit
        </Button> 
        
      </ListItem>
      <Divider  component="li" />
     </Fragment>))}
    </List>
          }   
         </Fragment> 
        :
        <Card className='box' sx={{ borderRadius: '9px', bgcolor: '#36492d', height: '100%', margin: 1}}>

          <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="body2" color="white">
          {coin.wallet_type === 'bank' ?
          `Kindly make payment to our ${coin.coin_name} account using the details below`
          : 
          `Kindly send ${coin.coin_name} to the provided  address below.`
          }
          
          </Typography>

          <CardContent sx={{textAlign: 'center'}}>

          {coin.wallet_type === 'Crypto' &&

        <QRCode qrStyle="dots" value={address} size={200} logoImage=
        {`https://assets.coincap.io/assets/icons/${coin.coin_name.toLowerCase()}@2x.png`}
        />
        }

            <Box my={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />           
            </Box>
           
          <CopyToClipboard onCopy={() => {setbuttonText( coin.wallet_type === 'bank'? 'Account Details Copied' : 'Address Copied');
              setTimeout(() => {
                  setbuttonText(null);
              }, 2000);}} text={address}>
              <FormControl  fullWidth={true} sx={{ minWidth: 50, marginBottom: 2 }}>
                  <TextField
                  minRows={1} multiline                  
                  defaultValue={address}
                  id="address"
                  name="address"
                  type={'text'}
                  label= {buttonText !== null? buttonText: `Click to copy ${coin.coin_name} ${coin.wallet_type === 'bank'? 'bank details' : 'address'} `}
                  />
              </FormControl> 
          </CopyToClipboard>

          <FormControl fullWidth={true} sx={{  minWidth: 50, marginBottom: 2 }}>
              <InputLabel htmlFor="amount">Amount (USDT)</InputLabel>
              {(error !== '' && error.amount) ?

              <OutlinedInput
              onChange={(e)=>setAmount(e.target.value)}
              error
              id="amount"
              type={'number'}
              name="amount"
              label="Amount (USDT)"
              helperText={error.amount}
              />
              :
              <OutlinedInput
              onChange={(e)=>setAmount(e.target.value)}
              id="amount"
              type={'number'}
              name="amount"
              label="Amount (USDT)"
              />
              }
              <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
              {error !== '' && error.amount}
              </Typography>
          </FormControl>
        
          </CardContent>

          <CardActions> 
          <Button onClick={()=>setCoin('')} fullWidth={true} edge="end" size="small" color="error" variant="contained"> 
          Cancel
        </Button> 

        <Button onClick={postRequest} fullWidth={true} edge="end" size="small" color="primary" variant="contained"> 
         {isSendingRequest? 'sending...' : 'Confirm Payment'} 
        </Button>

          </CardActions>
        </Card>
        
        }
    </div>
  )
}

export default Receive
